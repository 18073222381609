import StartBlock from "./StartBlock";
import Sportevent from "./Sportevent";
import User from "../basedata/User";
import DisciplineCategory from "../sporteventCalculation/DisciplineCategory";

export default class Jury {
  id?: string;
  sportevent: Sportevent = new Sportevent();
  startBlock: StartBlock = new StartBlock();
  disciplineCategory: DisciplineCategory = new DisciplineCategory();
  judges: User[] = [];
  numberOfJudges = 2;

  constructor(jury?: Jury) {
    if (jury === undefined || jury === null) {
      return;
    }
    this.id = jury.id;
    this.sportevent = jury.sportevent;
    this.startBlock = jury.startBlock;
    this.disciplineCategory = jury.disciplineCategory;
    this.numberOfJudges = jury.numberOfJudges;
    this.judges = [];
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";
import StartBlock from "../../models/sportevent/StartBlock";
import Discipline from "../../models/sporteventCalculation/Discipline";
import StartGroupService from "../../services/sportevent/StartGroupService";
import User from "../../models/basedata/User";
import JuryDisciplineComponent from "./JuryDiscipline.vue";

@Component({
  components: {
    JuryDisciplineComponent,
  },
})
export default class JuriesStartblockComponent extends Vue {
  public loading = true;

  @Prop({ required: true })
  public startBlock!: StartBlock;

  @Prop({ required: true })
  public users!: User[];

  public disciplines: Discipline[] = [];

  mounted(): void {
    this.getAll();
  }

  getName(user: User): string {
    return `${user.username}`;
  }

  change(): void {
    this.users = [];
  }

  async getAll(): Promise<void> {
    console.log("this.startBlock.id", this.startBlock.id);
    this.loading = true;
    if (this.startBlock.id != undefined) {
      const startgroups = await StartGroupService.getAllByStartBlock(
        this.startBlock.id
      );
      console.log("startgroups", startgroups);
      for (let i = 0; i < startgroups.length; i++) {
        for (let v = 0; v < startgroups[i].sporteventCompetitions.length; v++) {
          this.disciplines.push(
            ...startgroups[i].sporteventCompetitions[v].disciplines
          );
        }
      }
      // remove duplicates
      this.disciplines = this.disciplines.filter((obj, pos, arr) => {
        return arr.map((mapObj) => mapObj["name"]).indexOf(obj["name"]) === pos;
      });
    }
    console.log("disciplines", this.disciplines);
    this.loading = false;
    console.log("ende");
  }
}

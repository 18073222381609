
import { Component, Prop, Vue } from "vue-property-decorator";
import vMultiselectListbox from "vue-multiselect-listbox";
import i18n from "../../plugins/i18n";

import StartBlock from "../../models/sportevent/StartBlock";
import User from "../../models/basedata/User";
import JuryService from "../../services/sportevent/JuryService";
import Jury from "../../models/sportevent/Jury";
import ToastService from "../../services/helper/ToastService";
import DisciplineCategory from "../../models/sporteventCalculation/DisciplineCategory";

@Component({
  components: {
    "v-multiselect-listbox": vMultiselectListbox,
  },
})
export default class JuryDisciplineComponent extends Vue {
  public loading = true;

  @Prop({ required: true })
  public startBlock!: StartBlock;

  @Prop({ required: true })
  public users!: User[];

  @Prop({ required: true })
  public disciplineCategory!: DisciplineCategory;

  public selectedUsers: User[] = [];

  public jury = new Jury();

  async mounted(): Promise<void> {
    this.loadData();
  }

  async loadData(): Promise<void> {
    this.loading = true;
    if (
      this.startBlock.id !== undefined &&
      this.disciplineCategory.id !== undefined
    ) {
      const jury_return =
        await JuryService.getByStartBlockAndDisciplineCategory(
          this.startBlock.id,
          this.disciplineCategory.id
        );
      if (jury_return !== undefined) {
        this.jury = jury_return;
        this.selectedUsers = this.jury.judges;
      }
    }
    this.loading = false;
  }

  getName(user: User): string {
    return `${user.username}`;
  }

  change(): void {
    this.users = [];
  }

  async savejury(): Promise<void> {
    this.jury.sportevent = this.startBlock.sportevent;
    this.jury.disciplineCategory = this.disciplineCategory;
    this.jury.startBlock = this.startBlock;
    this.jury.judges = this.selectedUsers;

    const jury_return = await JuryService.save(this.jury);
    console.log(jury_return);
    if (jury_return) {
      this.jury = jury_return;
      await ToastService.Success(i18n.tc("labels.saved"), "");
    }
  }
}

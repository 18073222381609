
import { Component, Vue, Watch } from "vue-property-decorator";

import formatDate from "../../plugins/formatDate";

import StartBlock from "../../models/sportevent/StartBlock";
import SporteventService from "../../services/sportevent/SporteventService";
import StartBlockService from "../../services/sportevent/StartBlockService";

import JuriesStartblockComponent from "./JuriesStartblock.vue";
import User from "../../models/basedata/User";
import UserService from "../../services/basedata/UserService";

@Component({
  filters: {
    formatDate,
  },
  components: {
    JuriesStartblockComponent,
  },
})
export default class JuriesComponent extends Vue {
  // Loading
  public loading = true;
  public users: User[] = [];

  // Table
  public items: StartBlock[] = [];
  public fields = [
    {
      key: "actions",
      sortable: false,
      class: "text-center",
      label: "",
    },
  ];
  public filter = "";
  public perPage = 10;
  public pageOptions = [5, 10, 20, 30];
  public totalRows = 1;
  public currentPage = 1;

  mounted(): void {
    this.getAll();
  }

  async getAll(): Promise<void> {
    this.loading = true;
    const users = await UserService.getAllUsers();
    if (users !== undefined) {
      this.users = users;
    }

    const sportevent = SporteventService.get();
    if (sportevent.id != undefined) {
      this.items = await StartBlockService.getAllBySportevent(sportevent.id);
      if (this.items.length > 0) {
        this.items = this.items.sort((a, b) =>
          a.startBlockPosition > b.startBlockPosition ? 1 : -1
        );
      }
      this.loading = false;
    }
  }
}
